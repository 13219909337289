.mobile-wrapper {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  & .navmobile {
    background-color: @brand-secundary;
    #gradient > .vertical(darken(@brand-secundary, 20%); @brand-secundary; 30%; 100%);
    & ul {
      white-space: nowrap;
      margin: 0;
      font-size: 0;
      font-family: "Open Sans Condensed";
      text-transform: uppercase;
      & li {
        display: inline-block;
        margin: 0;
        padding: 0;
             white-space: normal;
        font-size: @font-size-base*1.2;
        & p.navmobile-text {
          margin: 0;
          padding: @padding-base-vertical @padding-base-horizontal;
          display: inline-block;
        }
        a {
          //background-color: @brand-primary;
          color: #fff;
          display: inline-block;
          padding: @padding-large-vertical @padding-small-horizontal;
          & i {
            color: rgba(255, 255, 255, 0.3);
            margin-bottom: 6px;
          }

          &:hover {
            background-color: @brand-secundary;
            color: @text-color;
            & i {
              color: lighten(@brand-secundary, 20%);
            }
          }
        }
        &.active, &:active {
          a {
            background-color: lighten(@brand-secundary, 5%);
            color: white;
          }
        }
      }
    }
  }
}

.navmenu-nav {
  & li {
    & a {
      & i {
        color: rgba(255, 255, 255, 0.4);
      }
      &:hover {
        background-color: @brand-secundary !important;
        & i {
          color: lighten(@brand-secundary, 20%);
        }
      }
    }
  }
}


.navigation-wrapper {
  //margin: 0;
  //padding:0;
  & .navtop {
    width: 100%;
    background-color: darken(@brand-secundary, 20%);
    height: 26px;
    line-height: 23px;
    .text-right();
    font-size: @font-size-base*0.9;
    box-shadow: inset 0 -4px 9px -4px rgba(0, 0, 0, 0.5);
    & ul.list {
      & li {
        color: darken(#ffffff, 20%);
        color: rgba(255, 255, 255, 0.5);
        & a {
          color: rgba(255, 255, 255, 0.7);
          & i {
            color: rgba(255, 255, 255, 0.3);
          }
        }
        &:hover a {
          color: #ffffff;
        }
      }
    }
  }

  & .navbar {
    background-color: @brand-secundary;
    #gradient > .vertical(darken(@brand-secundary, 20%); @brand-secundary; 30%; 100%);
    margin:0;
    font-family: "Open Sans";
    border: none;
    border-radius: 0px;
    transition: all 0.2s ease;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);

    & .container {
      padding-left: @padding-large-horizontal*2;
      padding-right: @padding-large-horizontal*2;
    }

    & .navbar-brand {
      width: 225px;
      max-width: 225px;
      padding: 0;
      transition: all 0.2s ease;
      & img {
        display: inline-block;
        margin: 0;
        height: 100%;
        width: 225px;
      }
    }

    & ul.navbar-nav {
      & li {
        border-right: 1px solid lighten(@brand-secundary, 3%);
        &:last-child {
          border: none;
        }
        & a {
          margin: 0px 0px;
          color: rgba(255, 255, 255, 0.6);
          text-transform: uppercase;
          text-align: center;
          & i {
            color: rgba(255, 255, 255, 0.3);
            margin-bottom: 6px;
          }
        }
        &:hover a {
          color: rgba(255, 255, 255, 0.8);
          background-color: lighten(@brand-secundary, 5%);
          #gradient > .vertical(darken(@brand-secundary, 20%); lighten(@brand-secundary, 5%); 30%; 100%);
          & i {
            color: lighten(@brand-secundary, 20%);
          }
        }
        &.active a, &:active a {
          color: rgba(255, 255, 255, 1.0);
          background-color: lighten(@brand-secundary, 5%);
          #gradient > .vertical(darken(@brand-secundary, 20%); lighten(@brand-secundary, 10%); 30%; 100%);
          & i {
            color: lighten(@brand-secundary, 50%);
          }
        }
        & .dropdown-menu {
          background-color: #ffffff;
          font-weight: 300;
          font-size: 16px;
          & li {
            border-bottom: 1px dotted @gray-lighter;
            & a {
              font-weight: 300;
              text-align: left;
              text-transform: none;

              color: @text-color;
              & i {
                color: @brand-primary;
                font-size: 100%;
              }
            }
          }
        }
      }
    }
    &.shrink {
      height: 80px;
      //line-height: 80px;
      min-height: 80px !important;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
      transition: all 0.2s ease;
      margin-top: 0px;
      & .navbar-brand {
        height: inherit;
        & img {
          margin: 17px auto;
        }
      }
      & ul.navbar-nav {
        & li a {
          //margin:0;
          padding-top:27px;
          padding-bottom:27px;
        }
      }
    }
  }
}

ul.list.list-unstyled {
  & li {
    & a {
      display: block;
      color: @text-color; //@brand-primary;
      padding: @padding-base-vertical 0;
      font-size: @font-size-base*1.2;
      line-height: 1;
    }
    &:hover, &.active {
      & a {
        color: @brand-primary;
      }
    }
  }
}

/* Large devices (large desktops, 1200px and up) */
@media only screen and  (max-width: @screen-lg-min) {
  .navbar {
    font-size: 17px;
    font-weight: 300;
    & ul.navbar-nav {
      & li {
        & a {
          //font-size: 20px;
          //color:blue;
          //padding-left: 5px;
          //padding-right: 5px;
        }
      }
    }
  }
}

ul.list.list-unstyled.navlist {
  & li {
    border-bottom: 1px solid @gray-lighter;
    & a {
      color: @text-color;
      text-transform: uppercase;
      font-size: @font-size-base;
      line-height: 1.3;
      padding: @padding-base-vertical 0;
    }
    &:hover a {
      color: @brand-primary;
    }
    &.active a {
      color: @brand-primary !important;
      font-weight: bold;
    }
  }
}

