@gray-base:              #000;
@gray-darker:            lighten(@gray-base, 13.5%); // #222
@gray-dark:              lighten(@gray-base, 20%);   // #333
@gray:                   lighten(@gray-base, 33.5%); // #555
@gray-light:             lighten(@gray-base, 46.7%); // #777
@gray-lighter:           lighten(@gray-base, 93.5%); // #eee


@brand-primary:         #ff8001;
@brand-secundary:       #022159;
@brand-success:         #5cb85c;
@brand-info:            #5bc0de;
@brand-warning:         #ff8001;
@brand-danger:          #d9534f;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg:               darken(@brand-secundary,20%);
//** Global text color on `<body>`.
@text-color:            #666;

//** Global textual link color.
@link-color:            @brand-primary;
//** Link hover color set via `darken()` function.
@link-hover-color:      darken(@link-color, 15%);
//** Link hover decoration.
@link-hover-decoration: underline;


@font-family-sans-serif:  "Lato", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

@font-size-base:          16px;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.85)); // ~12px

@font-size-h1:            floor((@font-size-base * 2.0)); // ~36px
@font-size-h2:            floor((@font-size-base * 2.0)); // ~30px
@font-size-h3:            floor((@font-size-base * 1.6)); // ~24px
@font-size-h4:            floor((@font-size-base * 1.4)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

@headings-font-family:    "Open Sans", Helvetica, Arial, sans-serif;
@headings-font-weight:    300;
@headings-line-height:    1.1;
@headings-color:          inherit;

//** Number of columns in the grid.
@grid-columns:              24;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width:        15px;


@screen-lg:                  1300px;
// Large screen / wide desktop
@container-large-desktop:      (1240px + @grid-gutter-width);

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
@grid-float-breakpoint:     @screen-md-min;


@navbar-height:                    90px;
@navbar-default-color:             darken(#ffffff,10%);
@navbar-default-bg:                @brand-primary;
@navbar-default-border:            transparent;//darken(@navbar-default-bg, 6.5%);

// Navbar links
@navbar-default-link-color:                #ffffff;
@navbar-default-link-hover-color:          #ffffff;
@navbar-default-link-hover-bg:             @brand-primary;
@navbar-default-link-active-color:         @brand-primary;
@navbar-default-link-active-bg:            lighten(@navbar-default-bg, 6.5%);
@navbar-default-link-disabled-color:       darken(@navbar-default-bg, 6.5%);
@navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
@navbar-default-brand-color:               @navbar-default-link-color;
@navbar-default-brand-hover-color:         darken(@navbar-default-brand-color, 10%);
@navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
@navbar-default-toggle-hover-bg:           lighten(@navbar-default-bg, 6.5%);
@navbar-default-toggle-icon-bar-bg:        darken(@navbar-default-bg, 6.5%);
@navbar-default-toggle-border-color:       #fff;

@dropdown-bg:                    @navbar-default-bg;
@dropdown-border:                none;
@dropdown-fallback-border:       none;
@dropdown-divider-bg:            lighten(@navbar-default-bg,10%);

@dropdown-link-color:            @navbar-default-link-color;
@dropdown-link-hover-color:      @navbar-default-link-hover-color;
@dropdown-link-hover-bg:         lighten(@navbar-default-bg,10%);;

@dropdown-link-active-color:     @component-active-color;
@dropdown-link-active-bg:        @component-active-bg;

@dropdown-link-disabled-color:   @gray-light;

@dropdown-header-color:          @gray-light;

@dropdown-caret-color:           #000;

//== Carousel
//
//##

@carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.8);

@carousel-control-color:                      #fff;
@carousel-control-width:                      15%;
@carousel-control-opacity:                    .5;
@carousel-control-font-size:                  20px;

@carousel-indicator-active-bg:                #fff;
@carousel-indicator-border-color:             #fff;

@carousel-caption-color:                      #fff;




/* Large devices (large desktops, 1200px and up) */
@media only screen and  (max-width: @screen-lg-min) {
  .navbar {
    font-size: 15px;
    & ul.navbar-nav {
      & li {
        & a {
          padding-left: 7px;
          padding-right: 7px;
        }
      }
    }
  }
  @font-size-h1:            floor((@font-size-base * 1.6)); // ~36px
  @font-size-h2:            floor((@font-size-base * 1.6)); // ~30px
  @font-size-h3:            floor((@font-size-base * 1.2)); // ~24px
  @font-size-h4:            floor((@font-size-base * 1.0)); // ~18px
  @font-size-h5:            @font-size-base;
  @font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px
}

/* Medium devices (desktops, 992px and up) */
@media only screen and (max-width: @screen-md-min) {
  .navbar {
    font-size: 17px !important;
    & ul.navbar-nav {
      & li {
        & a {
          padding-left: 6px;
          padding-right: 6px;
        }
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media only screen and  (max-width: @screen-sm-max) {
  .navbar {
    font-size: 16px !important;
    & ul.navbar-nav {
      & li {
        & a {
          //padding-left: 0px !important;
          //padding-right: 0px !important;
        }
      }
    }
  }

  @font-size-h1:            floor((@font-size-base * 1.6)); // ~36px
  @font-size-h2:            floor((@font-size-base * 1.6)); // ~30px
  @font-size-h3:            floor((@font-size-base * 1.2)); // ~24px
  @font-size-h4:            floor((@font-size-base * 1.0)); // ~18px
  @font-size-h5:            @font-size-base;
  @font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

}

@media only screen and  (max-width: @screen-xs-max) {
  .navbar {
    font-size: 16px !important;
  }

  @font-size-h1:            floor((@font-size-base * 1.6)); // ~36px
  @font-size-h2:            floor((@font-size-base * 1.6)); // ~30px
  @font-size-h3:            floor((@font-size-base * 1.2)); // ~24px
  @font-size-h4:            floor((@font-size-base * 1.0)); // ~18px
  @font-size-h5:            @font-size-base;
  @font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

}





