@import "bootstrap/bootstrap";
@import "variables";
@import "jasny/jasny-bootstrap";
@import "helpers";
@import "common";
@import "navigation";
@import "custom";
@import "homepage";
@import "products";
@import (less) "swiper.min.css";
@import "swiper";

@import "colorbox4/colorbox";
//@import "bootstrap-image-gallery.min.css";

@import url("//fonts.googleapis.com/css?family=Lato:100,400,400italic,700,900,400italic|Open+Sans:400,300,400italic,600,700|Open+Sans+Condensed:300,700");

//<link href='https://fonts.googleapis.com/css?family=Open+Sans:400,300,400italic,600,700|Open+Sans+Condensed:300,700' rel='stylesheet' type='text/css'>
@btn-font-weight: 400;
//@font-family-sans-serif: "Lato", Helvetica, Arial, sans-serif;

@import "/public/fonts/font-awesome/css/font-awesome.min.css";

body, label, .checkbox label {
  font-weight: 400;
}
body {
 // padding-top:130px;
}

/* Large devices (large desktops, 1200px and up) */
@media only screen and  (max-width: @screen-lg-min) {
  body {
 //   padding-top:48px;
  }
}
