/*
 * algemene headers
 * zie variables.less
 *
 */
.help-block.error {
  color:@brand-danger;
}
.wrapper {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  &:first-child {
    margin-top: 0;
  }
}

& h2 {
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  //&.underlined::before {
  //  content: '';
  //  position: absolute;
  //  bottom: -15px;
  //  left: 0px;
  //  border-bottom: 5px solid @brand-primary;
  //  height: 6px;
  //  width: 110px;
  //}
}

& h3 {
  position: relative;
  color:@brand-primary;
  //&.underlined::before {
  //  content: '';
  //  position: absolute;
  //  bottom: -10px;
  //  left: 0px;
  //  border-bottom: 3px solid @brand-primary;
  //  height: 5px;
  //  width: 110px;
  //}
}

& h4 {
  position: relative;
  color:@brand-primary;
  //&.underlined::before {
  //  content: '';
  //  position: absolute;
  //  bottom: -5px;
  //  left: 0px;
  //  border-bottom: 3px solid @brand-primary;
  //  height: 3px;
  //  width: 110px;
  //}
}

& a {
  color: @brand-primary;
  &:hover {
    text-decoration: none;
    //    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }
}

//.bg-2 {
//  background-color: darken(@brand-secundary,10%)
//}
//
//.bg-5 {
//  background-color: @brand-secundary;
//}
//
//.bg-10 {
//  background-color: darken(@brand-primary,10%)
//}
//
//.bg-20 {
//  background-color: @brand-primary;
//}
.fsize {
  &.fsize-xs {
    font-size: @font-size-base*0.8;
  }
  &.fsize-sm {
    font-size: @font-size-base*0.9;
  }
  &.fsize-md {
    font-size: @font-size-base;
  }
  &.fsize-lg {
    font-size: @font-size-base*1.1;
  }
  &.fsize-xl {
    font-size: @font-size-base*1.2;
  }
}

.gap {
  height: 10px;
  background-color: transparent;
  &.gap-sm {
    height: 5px;
  }
  &.gap-md {
    height: 10px;
  }
  &.gap-lg {
    height: 20px;
  }
  &.gap-xl {
    height: 40px;
  }
}

/*
 * algemene kleurvariaties
 *
 */
.bg {
  border: none;
  box-shadow: none;
  color: @text-color;
  background-color: white;
  & label {
    color: inherit;
  }
  & h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }
  & h1:first-child, & h2:first-child, & h3:first-child, & h4:first-child, & h5:first-child, & h6:first-child {
    margin-top: 0px;
  }
  & i {
    color: inherit;
  }
  //& a {
  //  color: inherit;
  //}

  &.bg-nolines {
    border: none;
  }

  &.bg-transparent {
    background-color: transparent;
  }

  &.bg-gradient {
    background: #25273f; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzI1MjczZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #25273f 0%, #000000 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #25273f), color-stop(100%, #000000)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #25273f 0%, #000000 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #25273f 0%, #000000 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #25273f 0%, #000000 100%); /* IE10+ */
    background: linear-gradient(to bottom, #25273f 0%, #000000 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#25273f', endColorstr='#000000', GradientType=0); /* IE6-8 */
  }
  &.bg-white, &.bg-default {
    background-color: #ffffff;
  }
  &.bg-light {
    background-color: @gray-lighter;
  }
  &.bg-lighter {
    background-color: @gray-lighter;
  }
  &.bg-medium {
    background-color: darken(@gray-lighter, 5%);
  }
  &.bg-dark {
    background-color: darken(@gray-lighter, 15%);
  }
  &.bg-black {
    background-color: @gray;
  }
  &.bg-image {
    color: #fff;
    background-color: @gray;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); //#000000;
  }

  &.bg-primary {
    background: @brand-primary;
    color: #ffffff;
    & label {
      color: inherit !important;
    }
    & h1, h2, h3, h4, h5, h6 {
      color: inherit !important;
    }
    & i {
      color: inherit !important;
    }
    & a {
      color: inherit !important;
    }
  }

  &.bg-primary-light {
    background: lighten(@brand-primary, 15%);
    color: @text-color;
    text-shadow: 1px 1px 1px #000000;
  }

  &.bg-secundary {
    background: @brand-secundary;
    color: #ffffff;
    text-shadow: 1px 1px 1px #000000;
    & label {
      color: inherit !important;
    }
    & h1, h2, h3, h4, h5, h6 {
      color: inherit !important;
    }
    & i {
      color: inherit !important;
    }
    & a {
      color: inherit !important;
    }
  }
  &.bg-secundary-light {
    background: lighten(@brand-secundary, 15%);
    color: @text-color;
    text-shadow: 1px 1px 1px #000000;
  }
}

.notpadded {
  padding: 0 0;

}

.padded {
  padding: @padding-base-vertical @padding-base-horizontal;
  &.padded-sm {
    padding: @padding-small-vertical @padding-small-horizontal;
  }
  &.padded-md {
    padding: @padding-base-vertical @padding-base-horizontal;
  }
  &.padded-lg {
    padding: @padding-large-vertical @padding-large-horizontal;
  }
  &.padded-xl {
    padding: @padding-large-vertical*3 @padding-large-horizontal*2;
  }
  &.padded-home {
    padding: @padding-large-vertical*4 @padding-large-horizontal*3;
  }
  &.padded-container {
    padding: 0 @padding-large-horizontal*2;
  }
}

@media (max-width: @screen-xs-max) {
  .padded {
    padding: @padding-base-vertical @padding-base-horizontal;
    &.padded-sm {
      padding: @padding-small-vertical @padding-small-horizontal;
    }
    &.padded-md {
      padding: @padding-base-vertical @padding-base-horizontal;
    }
    &.padded-lg {
      padding: @padding-large-vertical @padding-large-horizontal;
    }
    &.padded-xl {
      padding: @padding-large-vertical*2 @padding-large-horizontal*1;
    }
    &.padded-home {
      padding: @padding-large-vertical*2 @padding-large-horizontal*1;
    }
    &.padded-container {
      padding: 0 @padding-large-horizontal*2;
    }
  }
}

//.ellipsis {
//  display:block;
//  white-space: nowrap;
//  overflow: hidden;
//  text-overflow: ellipsis;
//}