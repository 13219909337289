.swiper-container {
  height: 400px; // old browsers
  height: 70vh; // overruled by style
  color: white;
  //background-color: lighten(@brand-primary, 30%) !important;

  & .swiper-slide {
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }

  &.inpage {
    width: 100%; // old browsers
    height: 100%; // overruled by style
    & .swiper-slide {
      font-size: 18px;
      color: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px 0px;

      //display: -webkit-box;
      //display: -ms-flexbox;
      //display: -webkit-flex;
      //display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      & img {
        width:100%;
      }
    }
  }

  .swiper-slide .title {
    //font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    //font-weight: 700;
    //font-size: 60px;
    //color: white;
    //text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

    //display:none;
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 20%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: @carousel-caption-color;
    text-align: left;
    text-shadow: @carousel-text-shadow;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 7vh;
    line-height:1.2;
    & .btn {
      text-shadow: none; // No shadow for button elements in carousel-caption
    }



  }

  .swiper-slide .subtitle {
    //font-size: 30px;
    //color: white;
    //display:none;
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 8%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: @brand-primary;//@carousel-caption-color;
    text-align: right;
    text-shadow: @carousel-text-shadow;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 4vh;
    line-height:1.2;
    & .btn {
      text-shadow: none; // No shadow for button elements in carousel-caption
    }
  }

  @media (max-width: @screen-sm-max) {
    .swiper-slide .title {
      bottom: 20%;
      font-size: 6vh;
    }
    .swiper-slide .subtitle {
      bottom: 12%;
      font-size: 3vh;
      font-weight: 400;
    }
  }


  //&.homepage {
  //  height: 800px; // old browsers
  //  height: 60vh; // overruled by style
  //  & a.swiper-scrolldown {
  //    display: block;
  //    position: absolute;
  //    z-index: 1000;
  //    bottom: 7vh;
  //    left: 50%;
  //    width: 60px;
  //    height: 60px;
  //    line-height: 60px;
  //    margin-left: -30px;
  //    text-align: center;
  //    background-color: rgba(0, 0, 0, 0.3);
  //    color: #ffffff !important;
  //    border-radius: 6px;
  //    & i {
  //      line-height: 60px;
  //    }
  //    &:hover {
  //      transition: all 0.2s ease;
  //      background-color: @brand-primary; //rgba(0, 0, 0, 0.3);
  //      color: #ffffff;
  //    }
  //  }
  //}

  //.swiper-button-prev {
  //  //.encoded-svg-background();
  //  left: 60px;
  //  & i {
  //    color: darken(@brand-primary,10%);
  //  }
  //  &:hover i {
  //    color: @brand-primary;
  //  }
  //}
  //.swiper-button-next {
  //  right: 60px;
  //  & i {
  //    color: darken(@brand-primary,10%);
  //  }
  //  &:hover i {
  //    color: @brand-primary;
  //  }
  //}
}





//.pagination {
//  position: absolute;
//  left: 0;
//  text-align: center;
//  bottom: 5px;
//  width: 100%;
//}
//
//.swiper-pagination-switch {
//  display: inline-block;
//  width: 10px;
//  height: 10px;
//  border-radius: 10px;
//  background: #999;
//  box-shadow: 0px 1px 2px #555 inset;
//  margin: 0 3px;
//  cursor: pointer;
//}
//
//.swiper-active-switch {
//  background: #fff;
//}
//
//.swiper-slide {
//  font-size: 18px;
//  color: #fff;
//  -webkit-box-sizing: border-box;
//  box-sizing: border-box;
//  padding: 40px 60px;
//}
//
//.parallax-bg {
//  position: absolute;
//  left: 0;
//  top: 0;
//  width: 130%;
//  height: 100%;
//  -webkit-background-size: cover;
//  background-size: cover;
//  background-position: center;
//}
//
//.swiper-slide .title {
//  font-family: "Open Sans Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
//  font-weight: 700;
//  font-size: 60px;
//  color: white;
//  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
//}
//
//.swiper-slide .subtitle {
//  font-size: 30px;
//  color: white;
//}
//
//.swiper-slide .text {
//  font-size: 14px;
//  max-width: 400px;
//  line-height: 1.3;
//  color: white;
//}

