//.header {
//  margin-bottom: @padding-large-vertical*2;
//}
[data-sr] {
  visibility: hidden;
}

.container {
  //  background-color: #ffffff;
}

.page-header {
  background-color:lighten(@gray-lighter,3%);
  border:none;
  & h2 {
    margin-bottom:0px;
  }
}

.breadcrumb {
  background-color: transparent;
  margin:0px 0px 0px @padding-large-horizontal;
  & li {
    & a {
      &:hover {
        color:@brand-primary;
      }
    }
    &.active a {
      color:#000000;
    }
  }

}

//ul.list.list-unstyled {
//  & li {
//    & a {
//      display: block;
//      color: @brand-primary;
//      padding: @padding-base-vertical 0;
//      font-size: @font-size-base*1.2;
//      line-height: 1;
//    }
//  }
//}

/* Product browse thumbnail */
a.thumbnail {
  display: block;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #fff;
  margin-bottom: 10px;
  padding: @padding-base-vertical*2 @padding-base-horizontal;
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
  &:hover, &.active, &:active {
    border: 1px solid @brand-primary;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    color: inherit;
    text-decoration: none;
    & h2 {
      color: @brand-primary;
    }
  }
  & h2 {
    padding: 0 @padding-base-horizontal*2;
  }
  & .intro {
    & h3 {
      font-size: @font-size-base*1.2;
    }
    padding: 0 @padding-base-horizontal*2;
  }
}

@blockquote-font-size: (@font-size-base * 1.15);

.well {
  padding: (@line-height-computed*1.5) @line-height-computed*2;

}

// Blockquotes
blockquote {
  padding: (@line-height-computed*1.5) @line-height-computed*2;
  margin: 0 0 @line-height-computed;
  font-size: @blockquote-font-size;
  border-left: none; // 5px solid @brand-primary;
  font-style: italic;
  color: lighten(@text-color, 20%);
  background-color: #EEEEEE;
  & h1, & h2, & h3, & h4 {
  font-family: @font-family-sans-serif;
  }
  & p, & ul, & ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  // Note: Deprecated small and .small as of v3.1.0
  // Context: https://github.com/twbs/bootstrap/issues/11660
  footer,
  small,
  .small {
    display: block;
    font-size: 80%; // back to default font-size
    line-height: @line-height-base;
    color: @blockquote-small-color;

    &:before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}


