.wrapper.homepage {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  & div.intro {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
  }
  & .well {
    & img.well-image {
      display:block;
      margin-bottom:15px;
      border: 3px solid white;
    }
    *:last-child {
      margin-bottom:0;
    }
  }

}