.products {

  &.browse {
    & a {
      & h2 {
        text-transform: uppercase;
      }
      & h3 {
        text-transform: none;
        font-size: @font-size-base*1.1 !important;
      }
    }
    .table {
      td:first-child {
        font-weight: 700;
        width: 33%;
      }
    }
  }
  &.detail {
    & .container {
      //padding: @padding-large-vertical*4 @padding-large-horizontal*2
      & .specs {
        & ul {
          .list-unstyled();
          & li {
            border-top: 1px solid @gray-light;
            &:first-child {
              border-top: none;
            }
            & a {
              display: block;
              font-size: @font-size-base;
              font-weight: 700;
              text-transform: uppercase;
              padding: @padding-small-vertical*0.75 30px @padding-small-vertical*0.75 0;
              color: @text-color;
              &:hover {
                color: @brand-primary;
              }
              &:after {
                font-family: FontAwesome;
                content: "\f068";
                position: absolute;
                right: 50px;
                color: @brand-primary;
              }
              &.collapsed:after {
                content: "\f067";
              }
            }
            & ul {
              padding: @padding-small-vertical*0.75 0;
              & li {
                border: none;
                padding: @padding-small-vertical*0.75 0;
              }
            }
          }
        }
      }
      & .well.bg-secundary {
        & ul {
          & li {
            color: rgba(255, 255, 255, 0.5);
            & a {
              color: lighten(@brand-secundary, 80%) !important;
              &:hover {
                color: lighten(@brand-secundary, 50%) !important;
              }
              &[href$=".pdf"]:after, &[href$=".PDF"]:after {
                font-family: "FontAwesome";
                content: "\f1c1";
                margin-left:5px;
                font-size:@font-size-base*0.8;
                color:red;
              }
            }
          }
        }
      }

      & .tabpanel {
        & h3 {
          line-height: 2;
          border-bottom: 1px solid darken(@gray-lighter, 10%);
        }
        & ul {
          border-left: 3px solid @brand-primary;
          & li {
            & a {
              text-transform: uppercase;
            }
            &:hover a {
              background-color: transparent;
              color: @brand-primary;
            }
            &.active a {
              background-color: transparent;
              color: @brand-primary;
              &:after {
                font-family: FontAwesome;
                content: "\f061";
                position: absolute;
                right: 10px;
                color: @brand-primary;
              }

            }
          }
        }
        & div.tabcontent {
          & h2 {

          }
          & h3 {

          }
        }
      }

    }
  }
}