.footer {
  margin-top: 0px;
  //padding-top:15px;
  background-color: darken(@brand-secundary,10%);
  #gradient > .vertical( @brand-secundary;darken(@brand-secundary,20%);  30%; 100%);
  color: rgba(255,255,255,0.6);

  & h4,& h3,& h4 {
    color: rgba(255,255,255,1);
  }
  & a {
    color: rgba(255,255,255,0.4);
    &:hover {
      color:rgba(255,255,255,1);
    }
  }
  & ul.list.list-unstyled {
    & li {
      color: rgba(255,255,255,1);
      & a {
        color: rgba(255,255,255,0.4);
        &:hover {
          color: rgba(255,255,255,1);
        }
      }
    }
  }

}